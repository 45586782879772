import cn from 'classnames'
import Decimal from 'decimal.js'
import React from 'react'
import {Link} from 'react-router-dom'
import {spacing} from '~/global/scss/helpers'
import {useLatestInstrumentReturns} from '~/global/state-hooks/rakaia/useLatestInstrumentReturns'
import useDividendReinvestmentPlan from '~/global/utils/use-dividend-reinvestment-plan/useDividendReinvestmentPlan'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import {Image} from '~/global/widgets/image/Image'
import cardIconDark from '~/sections/invest/sections/dividend-reinvestment-plans/assets/images/card-icon-dark.svg'
import cardIconLight from '~/sections/invest/sections/dividend-reinvestment-plans/assets/images/card-icon-light.svg'
import {useAppSelector} from '~/store/hooks'
import {Instrument} from '~/store/instrument/types'
import styles from './DividendReinvestmentAvailable.scss'

export const dividendOptInNextPage = (
    instrument: Instrument,
    hasHolding: boolean,
    profileUrl: ReturnType<typeof useProfileUrl>,
    hasSeenDRPLearn?: boolean,
) => {
    if (hasHolding) {
        if (hasSeenDRPLearn) {
            return profileUrl('invest/dividend-reinvestment-confirm/:instrumentSlug', {
                instrumentSlug: instrument.urlSlug,
            })
        }
        return profileUrl('invest/dividend-reinvestment-learn/:instrumentSlug', {
            instrumentSlug: instrument.urlSlug,
        })
    }
    return profileUrl('invest/dividend-reinvestment-learn')
}

interface DividendReinvestmentAvailableProps {
    className?: string
    instrument: Instrument
}

const DividendReinvestmentAvailable: React.FunctionComponent<DividendReinvestmentAvailableProps> = ({
    className,
    instrument,
}) => {
    const profileUrl = useProfileUrl()
    const hasSeenDRPLearn = useAppSelector(s => s.identity.user?.has_seen.dividend_reinvestment_plan)
    const [dividendReinvestmentPlan] = useDividendReinvestmentPlan(instrument)

    // Can enrol in a DRP with positive shares according to retail, or positive unvested shares according to rakaia
    const holding = useAppSelector(s => s.identity.holdings.find(f => f.fund_id === instrument?.id))
    const defaultPortfolioId = useAppSelector(s => s.identity.user!.portfolio_id)
    const instrumentReturns = useLatestInstrumentReturns(defaultPortfolioId).instrument_returns[instrument.id]

    const hasHolding = holding && new Decimal(holding.shares).greaterThan(0)
    const hasEligibleUnvestedHolding =
        dividendReinvestmentPlan?.unvested_shares_can_enrol && instrumentReturns?.unvested_detail.shares_unvested > 0

    const nextPageUrl = dividendOptInNextPage(
        instrument,
        (hasHolding || hasEligibleUnvestedHolding) ?? false,
        profileUrl,
        hasSeenDRPLearn,
    )

    return (
        <div className={cn(className, styles.cardWrapper)}>
            <div className={cn(styles.row, styles.top)}>
                <div className={styles.imageWrapper}>
                    <Image src={cardIconLight} darkSrc={cardIconDark} />
                </div>
                <div className={styles.col}>
                    <span className={styles.title}>Dividend reinvestment plan available</span>
                    <Link className={cn(styles.subTitle, spacing.spaceAbove8)} to={nextPageUrl}>
                        {holding && hasSeenDRPLearn ? 'Opt in' : 'Learn more'}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default DividendReinvestmentAvailable
