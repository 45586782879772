import React from 'react'
import {spacing} from '~/global/scss/helpers'
import useDividendReinvestmentPlan from '~/global/utils/use-dividend-reinvestment-plan/useDividendReinvestmentPlan'
import {Instrument} from '~/store/instrument/types'
import DividendReinvestmentAvailable from './widgets/dividend-reinvestment-available/DividendReinvestmentAvailable'
import DividendReinvestmentTile from './widgets/dividend-reinvestment-tile/DividendReinvestmentTile'

const DividendReinvestmentInstrument: React.FunctionComponent<{instrument: Instrument}> = ({instrument}) => {
    const [dividendReinvestmentPlan] = useDividendReinvestmentPlan(instrument)

    if (!dividendReinvestmentPlan) {
        return null
    }

    return dividendReinvestmentPlan.is_enrolled ? (
        <DividendReinvestmentTile className={spacing.spaceAbove32} instrument={instrument} />
    ) : (
        <DividendReinvestmentAvailable instrument={instrument} className={spacing.spaceAbove32} />
    )
}

export default DividendReinvestmentInstrument
