import {Button} from '@design-system/button'
import classNames from 'classnames'
import Decimal from 'decimal.js'
import React from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import NumberOneDark from '~/global/assets/images/numbers/number-one-dark.svg'
import NumberOneLight from '~/global/assets/images/numbers/number-one.svg'
import NumberThreeDark from '~/global/assets/images/numbers/number-three-dark.svg'
import NumberThreeLight from '~/global/assets/images/numbers/number-three.svg'
import NumberTwoDark from '~/global/assets/images/numbers/number-two-dark.svg'
import NumberTwoLight from '~/global/assets/images/numbers/number-two.svg'
import WeSlippedUp from '~/global/pages/error-screen/WeSlippedUp'
import {spacing} from '~/global/scss/helpers'
import {useLatestInstrumentReturns} from '~/global/state-hooks/rakaia/useLatestInstrumentReturns'
import useDividendReinvestmentPlan from '~/global/utils/use-dividend-reinvestment-plan/useDividendReinvestmentPlan'
import {useProfileUrl} from '~/global/utils/use-profile-url/useProfileUrl'
import ActionBar from '~/global/widgets/action-bar/ActionBar'
import {Image} from '~/global/widgets/image/Image'
import InstrumentLogo from '~/global/widgets/instrument-logo/InstrumentLogo'
import {Loading} from '~/global/widgets/loading/Loading'
import Page from '~/global/widgets/page/Page'
import {Toast} from '~/global/widgets/toast/Toast'
import {Toolbar} from '~/global/widgets/toolbar/Toolbar'
import {useAppDispatch, useAppSelector} from '~/store/hooks'
import identityActions from '~/store/identity/actions'
import instrumentActions from '~/store/instrument/actions'
import {getInstrumentFromSlug} from '~/store/instrument/selectors'
import {Instrument} from '~/store/instrument/types'
import styles from './DividendReinvestmentConfirmation.scss'

const DividendReinvestmentConfirmationWithInstrument: React.FunctionComponent<{instrument: Instrument}> = ({
    instrument,
}) => {
    const profileUrl = useProfileUrl()
    const dispatch = useAppDispatch()
    const [dividendReinvestmentPlan, setDrpEnrolment] = useDividendReinvestmentPlan(instrument)
    const instrumentPage = profileUrl('invest/:instrumentSlug', {instrumentSlug: instrument.urlSlug})
    const navigate = useNavigate()
    const {hasSeenDRPLearn, jurisdiction} = useAppSelector(s => ({
        hasSeenDRPLearn: s.identity.user?.has_seen.dividend_reinvestment_plan,
        jurisdiction: s.identity.user?.jurisdiction,
    }))

    // can enrol in a DRP with positive shares according to retail, or positive unvested shares according to rakaia
    const holding = useAppSelector(s => s.identity.holdings.find(f => f.fund_id === instrument?.id))
    const defaultPortfolioId = useAppSelector(s => s.identity.user!.portfolio_id)
    const instrumentReturns = useLatestInstrumentReturns(defaultPortfolioId).instrument_returns[instrument.id]

    const hasHolding = holding && new Decimal(holding.shares).greaterThan(0)
    const hasEligibleUnvestedHolding =
        dividendReinvestmentPlan?.unvested_shares_can_enrol && instrumentReturns?.unvested_detail.shares_unvested > 0

    const [buttonLoading, setButtonLoading] = React.useState(false)

    if (
        !dividendReinvestmentPlan ||
        !(hasHolding || hasEligibleUnvestedHolding) ||
        dividendReinvestmentPlan.is_enrolled
    ) {
        // We probably shouldn't be here
        return <Navigate to={instrumentPage} replace />
    }

    const onClick = async () => {
        setButtonLoading(true)

        try {
            await setDrpEnrolment(true)
            Toast(`You’ve opted in to ${instrument.name}’s dividend reinvestment plan — nice one!`)
            if (!hasSeenDRPLearn) {
                dispatch(identityActions.MarkHasSeenFlag('dividend_reinvestment_plan'))
                navigate(-2)
                return
            }

            navigate(-1)
        } catch (e) {
            Toast('Something went wrong, please try again.')
        }

        setButtonLoading(false)
    }

    return (
        <>
            <Toolbar dataTestId="toolbar--dividend-reinvestment-plan" leftButton="back" title="Confirmation" />
            <Page
                className={classNames(styles.dividendReinvestmentConfirmation)}
                overrideDefaultTopPadding="withToolbarTitle"
            >
                <div className={styles.card}>
                    <div>
                        <InstrumentLogo instrument={instrument} noBorder />
                    </div>
                    <div>
                        <h2>{instrument.name}’s dividend reinvestment plan</h2>
                        <p>You’re choosing to opt in</p>
                    </div>
                </div>
                <h2 className={spacing.spaceAbove24}>What to expect</h2>
                <ol>
                    <li>
                        <Image src={NumberOneLight} darkSrc={NumberOneDark} />
                        <span>
                            When you choose to opt in, we’ll let the company or fund know that you want your dividends
                            reinvested instead of being paid out in cash to your Wallet.
                        </span>
                    </li>
                    <li>
                        <Image src={NumberTwoLight} darkSrc={NumberTwoDark} />
                        <span>
                            You still pay tax on dividends received through a DRP. If a dividend is issued, we’ll
                            withhold enough money to cover{' '}
                            {jurisdiction === 'nz' ? 'your tax' : 'the tax owed to the IRD'}, and you’ll receive the
                            rest as shares.{' '}
                            <a
                                href={
                                    jurisdiction === 'nz'
                                        ? 'https://intercom.help/sharesies/en/articles/1236445-dividends#h_c5df9eebda'
                                        : 'https://intercom.help/sharesies-au/en/articles/7036960-reinvest-dividends#h_b3987730d4'
                                }
                                target="_blank"
                                rel="noopener"
                            >
                                Learn more
                            </a>
                        </span>
                    </li>
                    <li>
                        <Image src={NumberThreeLight} darkSrc={NumberThreeDark} />
                        <span>
                            You’ll see any shares (or part of a share) received through the DRP in the{' '}
                            <strong>Activity</strong> tab in your Portfolio, or under{' '}
                            <strong>Your investment {'>'} Investing activity</strong> on the investment’s page.
                        </span>
                    </li>
                </ol>
                <p>
                    Opting in to this DRP confirms that you have read and agree to the terms and conditions in{' '}
                    {instrument.name}’s{' '}
                    <a href={dividendReinvestmentPlan.offer_document_url} rel="noopener" target="_blank">
                        offer document
                    </a>
                    .
                </p>
            </Page>
            <ActionBar>
                <Button label="Confirm" dataTestId="button--confirm-drp" onClick={onClick} processing={buttonLoading} />
            </ActionBar>
        </>
    )
}

interface DividendReinvestmentConfirmationProps {
    instrumentSlug: string
}

const DividendReinvestmentConfirmation: React.FunctionComponent<DividendReinvestmentConfirmationProps> = ({
    instrumentSlug,
}) => {
    const dispatch = useAppDispatch()
    const instrument = useAppSelector(s => getInstrumentFromSlug(s, instrumentSlug))
    const resultsLoadingState = useAppSelector(s => s.instrument.resultsLoadingState)

    React.useEffect(() => {
        if (!instrument) {
            dispatch(instrumentActions.getSingleInstrumentBySlug(instrumentSlug))
        }
    }, [instrument])

    if (!instrument) {
        if (resultsLoadingState === 'loading') {
            return <Loading isPineapple />
        }

        return <WeSlippedUp />
    }

    return <DividendReinvestmentConfirmationWithInstrument instrument={instrument} />
}

export default DividendReinvestmentConfirmation
